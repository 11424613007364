/*
 * Copyright (C) 2023 to Present Applause App Quality, Inc. All rights reserved.
 */

import * as Ladda from 'ladda';
import {
  EMAIL_REGEX, NAME_REGEX,
} from '../constants';

class SimpleRegistrationFormCtrl {
  constructor($scope, UserService, ConfigService, vcRecaptchaService, $analytics, $location, AccessibleFormService) {
    const ctrl: ng.IController = this;

    ctrl.$onInit = function () {
      ctrl.spinnerBtn = Ladda.create(document.querySelector('#laddaBtn'));
      ctrl.name_pattern = new RegExp(NAME_REGEX);
      ctrl.email_pattern = new RegExp(EMAIL_REGEX);

      ctrl.registrationData = {
        firstName: '',
        lastName: '',
        email: '',
        paypalEmail: '',
        recaptcha_response: '',
      };
      ctrl.paypalEmailSame = false;
      ctrl.completedRegistration = false;
      ctrl.failedRegistration = false;

      const registrationQueryParameters = $location.search();
      if (!!registrationQueryParameters && Object.keys(registrationQueryParameters).length > 0) {
        ctrl.registrationData.registrationLocation = JSON.stringify(registrationQueryParameters);
      }

      ctrl.submissionInProgress = false;

      ConfigService.config().then((res) => {
        ctrl.globalConfig = res.data;

        ctrl.recaptchaKey = ctrl.globalConfig.recaptcha_client_key;
        ctrl.showExpiredRecaptchaError = false;
      });
    };

    ctrl.togglePaypalEmailSame = function () {
      ctrl.paypalEmailSame = !ctrl.paypalEmailSame;
      if (ctrl.paypalEmailSame) {
        ctrl.registrationData.paypalEmail = ctrl.registrationData.email;
      }
    };

    ctrl.updatePayPalEmail = function () {
      if (ctrl.paypalEmailSame) {
        ctrl.registrationData.paypalEmail = ctrl.registrationData.email;
      }
    };

    // FORM SUBMISSION
    ctrl.submitForm = function (form) {
      if (ctrl.isValidForm(form) && !ctrl.submissionInProgress) {
        ctrl.startSpinner();
        ctrl.createUser();
      } else {
        AccessibleFormService.focusFirstError(form, $scope);
      }
    };

    ctrl.isValidForm = function (form) {
      form.$$submitted = true;
      return !Object.keys(form.$error).length;
    };

    ctrl.createUser = function () {
      return UserService.createLightTester(ctrl.registrationData).then((response) => {
        ctrl.stopSpinner();
        ctrl.user = response.data;
        ctrl.customRegistration.embedded_form = ctrl.customRegistration.embedded_form.replace('$id$', encodeURIComponent(ctrl.user.testerId));
        ctrl.customRegistration.embedded_form = ctrl.customRegistration.embedded_form.replace('$name$', encodeURIComponent(ctrl.user.fullName));
        ctrl.customRegistration.embedded_form = ctrl.customRegistration.embedded_form.replace('$email$', encodeURIComponent(ctrl.user.email));
        ctrl.trackRegistration();
        ctrl.completedRegistration = true;
      }, () => {
        ctrl.stopSpinner();
        ctrl.failedRegistration = true;
      });
    };

    ctrl.isEmptyKeyInObject = function (obj) {
      if (Object.keys(obj).length > 0) {
        return !Object.keys(obj).every(key => obj[key] !== '' && obj[key] !== null && obj[key] !== undefined);
      }
      return true;
    };

    ctrl.startSpinner = function () {
      ctrl.submissionInProgress = true;
      ctrl.spinnerBtn.start();
    };

    ctrl.stopSpinner = function () {
      ctrl.spinnerBtn.stop();
      ctrl.spinnerBtn.remove();
      ctrl.submissionInProgress = false;
    };

    ctrl.trackRegistration = () => {
      const hostsToExclude = '(integration|stage|localhost)';
      if (!$location.$$host.match(hostsToExclude)) {
        $analytics.eventTrack('click', { category: 'Light User Registration', label: 'completed registration' });
      }
    };

    ctrl.setWidgetId = function (widgetId) {
      ctrl.captchaWidgetID = widgetId;
    };

    ctrl.onCaptchaResponse = function (response) {
      ctrl.showExpiredRecaptchaError = false;
      ctrl.registrationData.recaptcha_response = response;
    };

    ctrl.cbExpiration = function () {
      ctrl.showExpiredRecaptchaError = true;
      vcRecaptchaService.reload(ctrl.captchaWidgetID);
      ctrl.registrationData.recaptcha_response = null;
    };
  }
}

SimpleRegistrationFormCtrl.$inject = ['$scope', 'UserService', 'ConfigService', 'vcRecaptchaService', '$analytics', '$location', 'AccessibleFormService'];

export default SimpleRegistrationFormCtrl;
