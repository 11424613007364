class CommentBtnCtrl {
  constructor($state, LightTesterErrorService) {
    const $ctrl: ng.IController = this;

    $ctrl.$onInit = () => {
      if ($ctrl.commentable?.post_count < 0) {
        $ctrl.commentable.post_count = 0;
      }
      if ($ctrl.commentable?.comments?.count < 0) {
        $ctrl.commentable.comments.count = 0;
      }
    };

    $ctrl.onKeyPress = (event) => {
      if ([13, 32].includes(event.keyCode)) {
        event.preventDefault();
        if (LightTesterErrorService.show()) return;

        switch ($ctrl.commentable.type) {
          case 'article':
            $state.go('singlearticle', { articleId: $ctrl.commentable.slug });
            break;
          case 'topic':
            $state.go('singlediscussionpage', { page: 1, discussionId: $ctrl.commentable.id });
            break;
          case 'status':
            $state.go('singlestatus', { statusId: $ctrl.commentable.id });
            break;
          default: break;
        }
      }
    };

    $ctrl.checkLightTester = (e) => {
      if (LightTesterErrorService.show()) {
        e.preventDefault();
      }
    };
  }
}

CommentBtnCtrl.$inject = ['$state', 'LightTesterErrorService'];

export default CommentBtnCtrl;
