export default `
<div class="sign-up-form-container">
  <div class="registration-intro" ng-if="customRegistration.info_snippet && currentStep.number>0 && currentStep.number<5">
    <article ng-bind-html="customRegistration.info_snippet | uMarkdown | trustAsHtml"></article>
  </div>

  <div class="step-header h3" ng-if="shouldShowProgressBar()">
    <div class="row">
      <div class="col-md-8 col-sm-8 col-xs-12">
        <h1 class="step-title">
          <span ng-show="currentStep.number > 0 && currentStep.number < 5" ng-bind-html="'Step ' + currentStep.number + ': '"></span>
          <span class="sub-title" ng-bind-html="currentStep.title"></span>
        </h1>
      </div>
      <progress-step number-of-steps="4" step-number="currentStep.number"></progress-step>
    </div>
  </div>
  <div class="ui-view">
  </div>

  <div ng-if="currentStep.number==0">
    <div class="step-header h3" ng-if="!shouldShowProgressBar()">
      <div class="row">
        <div class="col-md-8 col-sm-8 col-xs-12">
          <h1 class="step-title">
            <span class="sub-title" ng-bind-html="customRegistration.title"></span>
          </h1>
        </div>
      </div>
    </div>
    <div class="registration-intro section-single-body">
      <div class="section-single-body-content">
        <article ng-bind-html="customRegistration.intro | uMarkdown | trustAsHtml"></article>
      </div>
    </div>
    <div class="form-group col-xs-12 text-right">
      <a aria-label="Signup & Apply Now" type="submit" class="btn btn-green btn-info btn-rounded" ng-click="firstStep();">
        <span>Signup & Apply Now</span>
        <i class="material-icons">chevron_right</i>
      </a>
    </div>
  </div>
</div>
`;
