class SlugInputCtrl {
  constructor(Slug) {
    const ctrl: ng.IController = this;
    let isAutomaticSlugActive;

    ctrl.$onInit = () => {
      ctrl.originalSlug = ctrl.theSlug;
      ctrl.editMode = ctrl.theSlug !== Slug.slugify(ctrl.toSlug);
      isAutomaticSlugActive = ctrl.toSlug === ctrl.theSlug;
    };

    ctrl.$onChanges = (change) => {
      if (isAutomaticSlugActive && change.toSlug && !ctrl.editMode) {
        ctrl.theSlug = Slug.slugify(ctrl.toSlug);
        ctrl.onSlugChange({ theSlug: ctrl.theSlug });
        ctrl.changeAlert = (ctrl.theSlug !== ctrl.originalSlug && ctrl.originalSlug !== '');
      }
    };

    ctrl.setOriginal = () => {
      ctrl.changeAlert = false;
      ctrl.editMode = false;
      ctrl.theSlug = ctrl.originalSlug;
      ctrl.onSlugChange({ theSlug: ctrl.theSlug });
      isAutomaticSlugActive = ctrl.toSlug === ctrl.theSlug;
    };

    ctrl.setAutomatic = () => {
      ctrl.theSlug = Slug.slugify(ctrl.toSlug);
      ctrl.onSlugChange({ theSlug: ctrl.theSlug });
      ctrl.changeAlert = (ctrl.theSlug !== ctrl.originalSlug && ctrl.originalSlug);
      ctrl.editMode = false;
      isAutomaticSlugActive = true;
    };

    ctrl.theSlugChange = () => {
      ctrl.hasError = false;
      if (ctrl.originalSlug.length) {
        ctrl.changeAlert = ctrl.theSlug !== ctrl.originalSlug;
      }
      ctrl.onSlugChange({ theSlug: ctrl.theSlug });
    };
  }
}

SlugInputCtrl.$inject = ['Slug'];

export default SlugInputCtrl;
