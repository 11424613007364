import _ from 'lodash';
import angular from 'angular';
import { StateService } from '@uirouter/core';
import { APPLICATION_SIZE_MODES } from '../../constants';
import setTabNavRoles from '../../helpers/tab-nav-roles';

class ProjectCtrl {
  constructor($scope, ProjectService, FeedStore, $state: StateService, $timeout: ng.ITimeoutService, $element, $window) {
    const ctrl: ng.IController = this;
    ctrl.tabs = ['suggested', 'all', 'urgent'];
    $scope.groups = {};
    $scope.filterProject = {};

    ctrl.calculateBgStyle = () => {
      let resUrl = ctrl.headerData.data.unauthCoverBg.small;
      if ($window.innerWidth >= APPLICATION_SIZE_MODES.WIDE) {
        resUrl = ctrl.headerData.data.unauthCoverBg.wide;
      } else if ($window.innerWidth >= APPLICATION_SIZE_MODES.MEDIUM) {
        resUrl = ctrl.headerData.data.unauthCoverBg.medium;
      }
      $scope.unauthHeaderBg = resUrl;
    };

    ctrl.$onInit = () => {
      $scope.currentUser = ctrl.user;
      $scope.headerData = ctrl.headerData.data;
      $scope.coverBg = ctrl.coverBg;
      $scope.availableCountries = ctrl.projectInfo.countries;
      $scope.availableOSs = ctrl.projectInfo.os;

      $scope.filterProject = sanitizeFilters(ctrl.$transition$.params());
      if ($scope.filterProject.country && !findCountry($scope.filterProject.country)) {
        delete $scope.filterProject.country;
        ctrl.updateUrl();
      }

      setupFeed();
      let tab = ctrl.$transition$.params().tab || 'suggested';
      if (($scope.filterProject.os || $scope.filterProject.country) && tab === 'suggested') {
        tab = 'all';
      }
      $scope.selectTab(tab);

      ctrl.calculateBgStyle();
      angular.element($window).on('resize', () => {
        ctrl.calculateBgStyle();
      });
    };

    ctrl.$onDestroy = () => {
      angular.element($window).off('resize');
    };

    $scope.selectTab = (tab: string) => {
      $scope.tab = tab;

      ctrl.setActiveTab($scope.tab);
      setTabNavRoles(document.getElementsByClassName('nav-tabs'));

      $scope.groups[$scope.tab].loadOnce();
      ctrl.updateUrl();
    };

    ctrl.updateFilter = () => {
      setupFeed();
      if ($scope.tab === 'suggested') {
        $scope.selectTab('all');
      } else {
        $scope.groups[$scope.tab].loadOnce();
        ctrl.updateUrl();
      }
    };

    const findCountry = country => (
      ($scope.availableCountries || []).find(s => s.toUpperCase() === country.toUpperCase())
    );

    const setupFeed = (resetAll = false) => {
      const fetchFeed = _.bind(ProjectService.feed, ProjectService);
      const sort = '-updated_at';
      if (resetAll || !$scope.groups.suggested) {
        setupSuggestedFeed(fetchFeed, sort);
      }

      const filters = { state: 'published', ...sanitizeFilters() };
      $scope.groups.all = new FeedStore(fetchFeed, { filter: filters, sort });
      $scope.groups.urgent = new FeedStore(fetchFeed, { filter: { ...filters, urgent: true }, sort });
    };

    const setupSuggestedFeed = (fetchFeed, sort) => {
      $scope.groups.suggested = new FeedStore(
        fetchFeed,
        { filter: { state: 'published', suggested_projects: true }, sort },
      );
      $scope.groups.suggested.loadOnce().then(() => {
        if ($scope.tab === 'suggested' && !$scope.shouldShow('suggested')) {
          $scope.selectTab('all');
        }
      });
    };

    const sanitizeFilters = ({ os, country } = $scope.filterProject) => {
      const filters = {};
      // @ts-ignore
      if (os) filters.os = os;
      // @ts-ignore
      if (country) filters.country = findCountry(country);

      return filters;
    };

    $scope.loadMoreItems = (feedGroup) => {
      const nextIdx = feedGroup.feedItems.length;
      feedGroup.loadMore().then((res) => {
        const nextElem = res.feedItems[nextIdx];
        if (nextElem !== undefined && nextElem.id !== undefined) {
          $timeout(() => {
            const renderedElem = $(`#project_title_${nextElem.id}`)[0];
            if (renderedElem !== undefined) {
              renderedElem.focus();
            }
          });
        }
      });
    };

    $timeout(() => {
      const container = $element.find('.ui-select-container');
      if (container.length > 0) {
        _.forEach(container, (elem) => {
          const txt = angular.element(elem).find('.ui-select-match').attr('placeholder');
          elem.setAttribute('aria-label', txt);
          angular.element(elem).find('.ui-select-match').attr('aria-label', txt);
          angular.element(elem).find('.ui-select-toggle').attr('aria-label', txt);
        });
      }
    }, 250);

    ctrl.setActiveTab = (tab: string) => {
      // @ts-ignore
      ctrl.tabStates = Object.fromEntries(ctrl.tabs.map(type => [type, false]));
      ctrl.tabStates[tab] = true;
    };

    ctrl.updateUrl = () => {
      const { country, os } = $scope.filterProject;
      $state.go('project', { tab: $scope.tab, country, os }, { location: 'replace' });
    };

    $scope.shouldShow = tab => {
      if (tab === 'suggested') {
        return $scope.groups.suggested.feedItems.length > 0 && !$scope.filterProject.os && !$scope.filterProject.country;
      }

      return true;
    };
  }
}

ProjectCtrl.$inject = [
  '$scope', 'ProjectService', 'FeedStore', '$state', '$timeout',
  '$element', '$window',
];

export default ProjectCtrl;
