import template from './article-feed-item.component.html';

const articleFeedItem = {
  bindings: {
    article: '<',
    featured: '<',
  },
  template,
};

export default articleFeedItem;
