/*
 * Copyright (C) 2023 to Present Applause App Quality, Inc. All rights reserved.
 */
import * as Ladda from 'ladda';

class SimpleRegistrationSetPasswordCtrl {
  constructor($scope, UserService, $location, $window, AccessibleFormService) {
    const ctrl: ng.IController = this;

    ctrl.$onInit = function () {
      ctrl.loading = true;
      ctrl.spinnerBtn = Ladda.create(document.querySelector('#laddaBtn'));
      const queryParams = $location.search();

      ctrl.emailCode = queryParams.code;
      ctrl.passwordCode = queryParams.userCode;

      ctrl.submissionInProgress = false;
      ctrl.linkExpired = false;
      ctrl.linkValid = ctrl.emailCode && ctrl.passwordCode;

      if (ctrl.linkValid) {
        ctrl.checkCode();
      }
      ctrl.passwordData = {
        password: '',
        confirmPassword: '',
      };

      UserService.me().then((res) => {
        if (res !== null) {
          $window.location = '/login';
        }
      });
    };

    ctrl.checkCode = function () {
      UserService.checkUserCodeValidity(ctrl.passwordCode)
        .then(() => {}, () => {
          ctrl.linkExpired = true;
        });
    };

    ctrl.submitForm = function (form) {
      if (ctrl.isValidForm(form) && !ctrl.submissionInProgress) {
        ctrl.startSpinner();
        ctrl.confirmEmailAndUpdatePassword();
      } else {
        AccessibleFormService.focusFirstError(form, $scope);
      }
    };

    ctrl.isValidForm = function (form) {
      form.$$submitted = true;
      return !Object.keys(form.$error).length;
    };

    ctrl.confirmEmailAndUpdatePassword = function () {
      // TODO will add better error handling conditions with UT-2309 and UT-2310
      return UserService.confirmEmail(ctrl.emailCode).then(() => {
        UserService.updatePassword(ctrl.passwordData.password, ctrl.passwordCode)
          .then(() => {
            ctrl.stopSpinner();
            $window.location = '/login';
          }, () => {
            ctrl.stopSpinner();
            $window.location = '/login';
          });
      }, () => UserService.updatePassword(ctrl.passwordData.password, ctrl.passwordCode).then(() => {
        ctrl.stopSpinner();
        $window.location = '/login';
      }));
    };

    ctrl.startSpinner = function () {
      ctrl.submissionInProgress = true;
      ctrl.spinnerBtn.start();
    };

    ctrl.stopSpinner = function () {
      ctrl.spinnerBtn.stop();
      ctrl.spinnerBtn.remove();
      ctrl.submissionInProgress = false;
    };
  }
}

SimpleRegistrationSetPasswordCtrl.$inject = ['$scope', 'UserService', '$location', '$window', 'AccessibleFormService'];

export default SimpleRegistrationSetPasswordCtrl;
