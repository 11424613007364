import _ from 'lodash';
import angular from 'angular';
import { EMPTY_CONTENT_MARKDOWNS } from '../constants';

class NewDiscussionCtrl {
  constructor($scope, $state, DiscussionCategoryService, DiscussionService, CommentService, AlertService, ProgressBarService, ServerMessages, $timeout, $element) {
    DiscussionCategoryService.allForSelect().then((categories) => { $scope.categories = categories; });
    const goToTopic = function (item) {
      $state.go('singlediscussionpage', { page: 1, discussionId: item.id });
    };

    $scope.instructions = [{
      type: 'warning',
      message: 'When creating a new forums post, please make sure to check if your question or topic has previously been posted.',
    }];

    $scope.closeAlert = function (index) {
      $scope.instructions.splice(index, 1);
    };

    $scope.discussion = {};

    $scope.saveInProgress = false;

    $scope.save = function () {
      const trimmedComment = _.trim($scope.discussion.content);
      if (_.isEmpty(trimmedComment) || EMPTY_CONTENT_MARKDOWNS.includes(trimmedComment)) {
        AlertService.addDanger('Can\'t post empty content!');
        return;
      }

      if ($scope.discussion && $scope.discussion.category && $scope.discussion.title && ($scope.discussion.content ? $scope.discussion.content.replace(/\s/g, '').length > 0 : false) && !$scope.saveInProgress) {
        $scope.saveInProgress = true;

        ProgressBarService.start();

        DiscussionService.create({
          title: $scope.discussion.title,
          category_id: $scope.discussion.category.id,
          content: $scope.discussion.content,
        }).then((res) => {
          const newItem = res.data;
          goToTopic(newItem);
          AlertService.add('Forum topic successfully created');
          ProgressBarService.done();
        }, (response) => {
          AlertService.addDanger(ServerMessages.prettyMessage(response));
          $scope.saveInProgress = false;
          ProgressBarService.done();
        });
      } else {
        AlertService.add('Please fill out all the fields', 'danger');
      }
    };
    setTimeout(() => {
      const categorySelect = $('.ui-select-focusser');
      if (categorySelect[0] !== undefined) {
        categorySelect[0].removeAttribute('role');
        categorySelect[0].setAttribute('aria-required', 'true');
        categorySelect[0].setAttribute('aria-label', 'Select a category');
      }
    }, 100);

    $timeout(() => {
      const container = $element.find('.ui-select-container');
      if (container.length > 0) {
        _.forEach(container, (elem) => {
          const txt = 'Select category';
          elem.setAttribute('aria-label', txt);
          angular.element(elem).find('.ui-select-match').attr('aria-label', txt);
          angular.element(elem).find('.ui-select-toggle').attr('aria-label', txt);
        });
      }
    }, 250);
  }
}

NewDiscussionCtrl.$inject = ['$scope', '$state', 'DiscussionCategoryService', 'DiscussionService', 'CommentService', 'AlertService', 'ProgressBarService', 'ServerMessages', '$timeout', '$element', 'featureFlags'];

export default NewDiscussionCtrl;
