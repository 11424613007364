import template from './comment-btn.component.html';
import controller from './comment-btn.controller';

const commentBtn = {
  bindings: {
    commentable: '<',
  },
  template,
  controller,
};

export default commentBtn;
