export default `
<div class="community-feed-item__button community-feed-item__add-comment-button">
  <a ng-if="$ctrl.commentable.type == 'article'" ui-sref="singlearticle({articleId : $ctrl.commentable.slug})" ng-keydown="$ctrl.onKeyPress($event)"
     aria-label="to article, {{$ctrl.commentable.title}}, {{$ctrl.commentable.comments.count}} comments {{$ctrl.commentable.comments.commented_by_current_user ? ' - you have commented on this article' : ''}}"
     ng-click="$ctrl.checkLightTester($event)">
    <i class="material-icons" aria-hidden="true" ng-class="{active: $ctrl.commentable.comments.commented_by_current_user}">comment</i>
    <span class="comment-count" aria-hidden="true">{{$ctrl.commentable.comments.count}}</span></a>
  <a ng-if="$ctrl.commentable.type == 'topic'" ui-sref="singlediscussionpage({page: 1, discussionId : $ctrl.commentable.id})" ng-keydown="$ctrl.onKeyPress($event)"
     aria-label="to topic, {{$ctrl.commentable.title}}, {{$ctrl.commentable.post_count}} comments {{$ctrl.commentable.comments.commented_by_current_user ? ' - you have commented on this topic' : ''}}"
     ng-click="$ctrl.checkLightTester($event)">
    <i class="material-icons" aria-hidden="true" ng-class="{active: $ctrl.commentable.comments.commented_by_current_user}">comment</i>
    <span class="comment-count" aria-hidden="true">{{$ctrl.commentable.post_count}}</span></a>
  <a ng-if="$ctrl.commentable.type == 'status'" ui-sref="singlestatus({statusId : $ctrl.commentable.id})" ng-keydown="$ctrl.onKeyPress($event)"
     aria-label="to status, by {{$ctrl.commentable.user.name}}, {{$ctrl.commentable.comments.count}} comments {{$ctrl.commentable.comments.commented_by_current_user ? ' - you have commented on this status' : ''}}"
     ng-click="$ctrl.checkLightTester($event)">
    <i class="material-icons" aria-hidden="true" ng-class="{active: $ctrl.commentable.comments.commented_by_current_user}">comment</i>
    <span class="comment-count" aria-hidden="true">{{$ctrl.commentable.comments.count}}</span></a>
</div>
`;
