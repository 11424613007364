export default `
<div class="activity-list-item">

  <img ng-if="['avatar', 'badge'].includes($ctrl.iconType)" class="activity-list-item__icon" ng-class="$ctrl.icon.modifier" ng-src="{{$ctrl.iconContent}}">
  <div ng-if="$ctrl.iconType == 'glyph'" class="activity-list-item__icon activity-list-item__icon--glyph" ng-class="$ctrl.iconModifier"><i class="material-icons">{{$ctrl.iconContent}}</i></div>

  <div class="activity-list-item__description" ng-switch="$ctrl.activity.type">

    <div ng-switch-when="badge_activity">
      You have received the <a ui-sref="gamification"><b>{{$ctrl.badge.name}}</b></a> badge and earned <b>{{$ctrl.badge.level}}</b> points
    </div>

    <div ng-switch-when="follow_activity">
      <a ui-sref="profile.about({profileId: $ctrl.activity.user.slug})">{{$ctrl.activity.user.name}}</a> started following you
    </div>

    <div ng-switch-when="reject_activity">
      Your article <a ui-sref="singlearticle({articleId: $ctrl.activity.item.slug})">{{$ctrl.activity.item.title}}</a> has been rejected
    </div>

    <div ng-switch-when="publish_activity">
      Your article <a ui-sref="singlearticle({articleId: $ctrl.activity.item.slug})">{{$ctrl.activity.item.title}}</a> has been approved
    </div>

    <div ng-switch-when="comment_activity" ng-switch="$ctrl.activity.commentable.type">
      <a ui-sref="profile.about({profileId: $ctrl.activity.user.slug})">{{$ctrl.activity.user.name}}</a>

      <span ng-switch-when="article">
        commented on {{ ($ctrl.activity.commentable.user.id == $ctrl.currentUser.id) ? 'your' : 'an'}} article:
        <a ui-sref="singlearticle({articleId: $ctrl.activity.commentable.slug})">{{$ctrl.activity.commentable.title}}</a>
      </span>

      <span ng-switch-when="status">
        commented on
        <a ui-sref="singlestatus({statusId: $ctrl.activity.commentable.id})">{{ ($ctrl.activity.commentable.user.id == $ctrl.currentUser.id) ? 'your' : 'a'}} status</a>
      </span>

      <span ng-switch-when="topic">
        commented on {{ ($ctrl.activity.commentable.user.id == $ctrl.currentUser.id) ? 'your' : 'a'}} forum post:
        <a ui-sref="singlediscussionpage({page: 1, discussionId: $ctrl.activity.commentable.id})">{{$ctrl.activity.commentable.title}}</a>
      </span>

      <span ng-switch-when="course">
        commented on {{ ($ctrl.activity.commentable.user.id == $ctrl.currentUser.id) ? 'your' : 'a'}} course:
        <a ui-sref="singlecourse({courseId: $ctrl.activity.commentable.slug})">{{$ctrl.activity.commentable.title}}</a>
      </span>
    </div>

    <div ng-switch-when="like_activity" ng-switch="$ctrl.activity.item.type">
      <a ui-sref="profile.about({profileId: $ctrl.activity.user.slug})">{{$ctrl.activity.user.name}}</a>

      <span ng-switch-when="status">
        liked
        <a ui-sref="singlestatus({statusId: $ctrl.activity.item.id})">your status</a>
      </span>

      <span ng-switch-when="article">
        liked your article:
        <a ui-sref="singlearticle({articleId: $ctrl.activity.item.slug})">{{$ctrl.activity.item.title}}</a>
      </span>

      <span ng-switch-when="topic">
        liked your forum post:
        <a ui-sref="singlediscussionpage({page: 1, discussionId: $ctrl.activity.item.id})">{{$ctrl.activity.item.title}}</a>
      </span>

      <span ng-switch-when="course">
        liked your course:
        <a ui-sref="singlecourse({courseId: $ctrl.activity.item.slug})">{{$ctrl.activity.item.title}}</a>
      </span>

      <span ng-switch-when="comment">
        liked your

        <span ng-if="$ctrl.activity.item.commentable.type === 'article'">
          <a ui-sref="singlearticle({articleId: $ctrl.activity.item.commentable.slug, comments: $ctrl.activity.item.page, '#': 'comment_' + $ctrl.activity.item.id})">comment</a>
          on the article: <a ui-sref="singlearticle({articleId: $ctrl.activity.item.commentable.slug})">{{$ctrl.activity.item.commentable.title}}</a>
        </span>

        <span ng-if="$ctrl.activity.item.commentable.type === 'topic'">
          <a ui-sref="singlediscussionpage({page: $ctrl.activity.item.page, discussionId: $ctrl.activity.item.commentable.slug, '#': 'comment_' + $ctrl.activity.item.id})">comment</a>
          on the forum post: <a ui-sref="singlediscussionpage({page: 1, discussionId: $ctrl.activity.item.commentable.slug})">{{$ctrl.activity.item.commentable.title}}</a>
        </span>

        <span ng-if="$ctrl.activity.item.commentable.type === 'course'">
          <a ui-sref="singlecourse({courseId: $ctrl.activity.item.commentable.slug, comments: $ctrl.activity.item.page, '#': 'comment_' + $ctrl.activity.item.id})">comment</a>
          on the course: <a ui-sref="singlecourse({courseId: $ctrl.activity.item.commentable.slug})">{{$ctrl.activity.item.commentable.title}}</a>
        </span>

        <span ng-if="$ctrl.activity.item.commentable.type === 'status'">
          <a ui-sref="singlestatus({statusId: $ctrl.activity.item.commentable.slug, comments: $ctrl.activity.item.page, '#': 'comment_' + $ctrl.activity.item.id})">comment</a>
          on
          <a ui-sref="singlestatus({statusId: $ctrl.activity.item.commentable.slug})">a status</a>
        </span>
      </span>
    </div>

    <div ng-switch-when="test_cycle_status_activity" ng-switch="$ctrl.activity.item.test_cycle_status_id">
      <span ng-switch-when="2">
        <b>Activated</b> -
        <a ng-href="{{$ctrl.testerAppLink}}">{{$ctrl.activity.item.name}}</a>
        has been activated
      </span>

      <span ng-switch-when="3">
        <b>Locked</b> -
        <a ng-href="{{$ctrl.testerAppLink}}">{{$ctrl.activity.item.name}}</a>
        is Locked
      </span>

      <span ng-switch-when="6">
        <b>Pending Activation</b> -
        <a ng-href="{{$ctrl.testerAppLink}}">{{$ctrl.activity.item.name}}</a>
        is Pending Activation
      </span>
    </div>

    <div ng-switch-when="test_cycle_invite_activity">
      <b>Invitation</b>
      - <a ng-href="{{$ctrl.testerAppLink}}">{{$ctrl.activity.item.name}}</a>
      - #{{$ctrl.activity.item.id}}
      <span ng-hide="$ctrl.activity.item.hide_company_and_product">
        - {{$ctrl.activity.item.company_name}}
        - {{$ctrl.activity.item.product_name}}
      </span>
    </div>

    <div ng-switch-when="requirement_survey_invite_activity">
      <b>Special Requirement Survey</b>
      - <a ng-href="{{$ctrl.testerAppLink}}">{{$ctrl.activity.item.requirement_survey.name}}</a>
    </div>

    <div ng-switch-when="available_waitlist_slot_activity">
      <b>Waitlisted Slot is Available</b>
      - <a ng-href="{{$ctrl.testerAppLink}}">{{$ctrl.textContent($ctrl.activity.item.slot.description)}}</a>
      - Claim the slot as soon as you’re ready to test.
    </div>

    <div ng-switch-when="removed_from_waitlist_activity">
      <b>Removed From a Slot Waitlist</b>
      - <a ng-href="{{$ctrl.testerAppLink}}">{{$ctrl.textContent($ctrl.activity.item.slot.description)}}</a>
      - The slot criteria changed. Please check to see if there’s a different slot that matches your profile.
    </div>

    <div ng-switch-when="bug_message_activity">
      <b>New Message</b>
      <span ng-if="$ctrl.activity.item.bug">
        - <a ng-href="{{$ctrl.testerAppLink}}">{{$ctrl.activity.item.bug.subject}}</a>
      </span>
      <span  ng-if="$ctrl.activity.item.test_run_result">
        - <a ng-href="{{$ctrl.testerAppLink}}">{{$ctrl.activity.item.test_run_result.test_case.name}}</a>
      </span>
    </div>

    <div ng-switch-when="bug_activity" ng-switch="$ctrl.activity.item.bug_status_id">
      <span ng-switch-when="2">
        <b>Approved</b>
        - <a ng-href="{{$ctrl.testerAppLink}}">Bug Report {{$ctrl.activity.item.bug.id}}</a> has been Approved
        - {{$ctrl.activity.item.bug.subject}}
      </span>

      <span ng-switch-when="3">
        <b>Rejected ({{$ctrl.bugChangeReasons[$ctrl.activity.item.bug_change_reason_id]}})</b>
        - <a ng-href="{{$ctrl.testerAppLink}}">Bug Report {{$ctrl.activity.item.bug.id}}</a> has been Rejected ({{$ctrl.bugChangeReasons[$ctrl.activity.item.bug_change_reason_id]}})
        - {{$ctrl.activity.item.bug.subject}}
      </span>

      <span ng-switch-when="4">
        <b>Disputed</b>
        - <a ng-href="{{$ctrl.testerAppLink}}">Bug Report {{$ctrl.activity.item.bug.id}}</a>
        - {{$ctrl.activity.item.bug.subject}}
      </span>

      <span ng-switch-when="8">
        <b>Bug Discarded</b>
        - <a ng-href="{{$ctrl.testerAppLink}}">Bug Report {{$ctrl.activity.item.bug.id}}</a>
        - {{$ctrl.activity.item.bug.subject}}
      </span>

      <span ng-switch-when="9">
        <b>Info requested</b>
        - <a ng-href="{{$ctrl.testerAppLink}}">Bug Report {{$ctrl.activity.item.bug.id}}</a>
        - {{$ctrl.activity.item.bug.subject}}
      </span>

      <span ng-switch-when="10">
        <b>Under Review</b>
        - <a ng-href="{{$ctrl.testerAppLink}}">Bug Report {{$ctrl.activity.item.bug.id}}</a>
        - {{$ctrl.activity.item.bug.subject}}
      </span>

      <span ng-switch-when="11|12" ng-switch-when-separator="|">
        <b>Pending</b>
        - <a ng-href="{{$ctrl.testerAppLink}}">Bug Report {{$ctrl.activity.item.bug.id}}</a>
        - {{$ctrl.activity.item.bug.subject}}
      </span>
    </div>

    <div ng-switch-when="test_case_activity" ng-switch="$ctrl.activity.item.approval_status_id">
      <span ng-switch-when="2">
        <b>Approved</b>
        - <a ng-href="{{$ctrl.testerAppLink}}">Test Case {{$ctrl.activity.item.test_run_result.id}}</a> has been Approved
        - {{$ctrl.activity.item.test_run_result.test_case.name}}
      </span>

      <span ng-switch-when="3">
        <b>Rejected</b>
        - <a ng-href="{{$ctrl.testerAppLink}}">Test Case {{$ctrl.activity.item.test_run_result.id}}</a> has been Rejected
        - {{$ctrl.activity.item.test_run_result.test_case.name}}
      </span>
      <span ng-switch-when="6">
        <b>Info Requested</b>
        - <a ng-href="{{$ctrl.testerAppLink}}">Test Case {{$ctrl.activity.item.test_run_result.id}}</a>
        - {{$ctrl.activity.item.test_run_result.test_case.name}}
      </span>
    </div>

    <div ng-switch-when="at_mention_activity" ng-switch="$ctrl.activity.item.type">
      <a ui-sref="profile.about({profileId: $ctrl.activity.user.slug})">{{$ctrl.activity.user.name}}</a> mentioned you in

      <span ng-switch-when="article">
        <a ui-sref="singlearticle({articleId: $ctrl.activity.item.slug})">an article</a>
      </span>

      <span ng-switch-when="status">
        <a ui-sref="singlestatus({statusId: $ctrl.activity.item.id})">a status</a>
      </span>

      <span ng-switch-when="comment">
        <a ng-if="$ctrl.activity.item.commentable.type === 'article'" ui-sref="singlearticle({articleId: $ctrl.activity.item.commentable.slug})">a comment</a>
        <a ng-if="$ctrl.activity.item.commentable.type === 'status'" ui-sref="singlestatus({statusId: $ctrl.activity.item.commentable.slug})">a comment</a>
        <a ng-if="$ctrl.activity.item.commentable.type === 'topic'" ui-sref="singlediscussionpage({page: 1, discussionId: $ctrl.activity.item.commentable.slug})">a forum post</a>
        <a ng-if="$ctrl.activity.item.commentable.type === 'course'" ui-sref="singlecourse({courseId: $ctrl.activity.item.commentable.slug})">a comment</a>
      </span>
    </div>
  </div>

  <div class="activity-list-item__time">{{$ctrl.activity.date | relativeDate}}</div>
</div>
`;
