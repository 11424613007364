/*
 * Copyright (C) 2021 to Present Applause App Quality, Inc. All rights reserved.
 */
import _ from 'lodash';
import angular from 'angular';

class ProfileCtrl {
  constructor(
    $scope,
    UserService,
    StatusService,
    CourseService,
    ToolService,
    ArticleService,
    GamificationService,
    FeedStore,
    user,
    CommentService,
    ProgressBarService,
    AlertService,
    ServerMessages,
    stateStore,
    $state,
    $timeout,
    store,
    ConfigService,
    LightTesterErrorService,
    $q,
    $location,
    $window,
    featureFlags,
    $element,
  ) {
    $scope.badgePresentation = GamificationService.badgesById;
    $scope.stateStore = stateStore;

    $scope.cs = CommentService;

    $scope.$state = $state;

    $scope.user = user.data;
    $scope.user_role = UserService.userRoleTitle($scope.user);
    if ($scope.user && $scope.user.professional_summary) {
      $scope.user.professional_summary = $scope.user.professional_summary.replace(/<[^>]*>/g, (found: string) => (found.indexOf('>>') === 0 || found.indexOf('<<') === 0
        ? found : found.replace(/</g, ' &lt;').replace(/>/g, ' &gt; ')));
    }

    $scope.statusStore = new FeedStore(
      _.bind(StatusService.feed, StatusService),
      { sort: 'pinned_content,-published_at', filter: { user_id: $scope.user.id } },
    );

    $scope.courseStore = new FeedStore(
      _.bind(CourseService.feed, CourseService),
      { filter: { user_id: $scope.user.id, state: 'published' } },
    );

    $scope.articleStore = new FeedStore(
      _.bind(ArticleService.feed, ArticleService),
      { sort: 'pinned_content,-published_at', filter: { user_id: $scope.user.id, state: 'published' } },
    );

    $scope.toolStore = new FeedStore(
      _.bind(ToolService.feed, ToolService),
      { filter: { user_id: $scope.user.id, state: 'published' } },
    );

    $scope.commentsStore = new FeedStore(_.bind(UserService.comments, UserService, $scope.user.id));
    $scope.mentionsStore = new FeedStore(_.bind(UserService.mentions, UserService, $scope.user.id));
    $scope.topicsStore = new FeedStore(_.bind(UserService.topics, UserService, $scope.user.id));
    $scope.likesStore = new FeedStore(_.bind(UserService.likes, UserService, 'me'));

    if (!$scope.user.profile_banner) {
      $scope.user.profile_banner = {
        url: 'assets/images/profile-cover-bg.jpg',
      };
    } else {
      $scope.user.profile_banner.url = encodeURI($scope.user.profile_banner.url);
    }

    $scope.currentUser = {};

    const configPromise = ConfigService.config();
    const userPromise = UserService.me();

    userPromise.then((userResponse) => {
      if (userResponse !== null) {
        $scope.currentUser = userResponse.data;
        $scope.canCreateArticle = $scope.currentUser.current_user_permissions.create_article;
        $scope.isLoggedInUser = $scope.user && $scope.currentUser && ($scope.user.id === $scope.currentUser.id);
      } else {
        $scope.currentUser = {};
        $scope.canCreateArticle = false;
        $scope.isLoggedInUser = false;
      }
    });

    $q.all([configPromise, userPromise]).then(([configRes, userRes]) => {
      if (userRes !== null) {
        $scope.referralLink = `${configRes.data.utest_url}/ref${userRes.data.platform_id}`;
        $scope.editProfileLink = `${configRes.data.profile_url}/account/my-profile/`;
      } else {
        $scope.referralLink = '';
        $scope.editProfileLink = '';
      }
    });

    $scope.statusWritten = function (status) {
      if ($scope.statusStore.feedItems) {
        $scope.statusStore.feedItems.unshift(status);
      }
    };

    $scope.deleteStatus = function (status) {
      _.remove($scope.statusStore.feedItems, status);
    };

    $scope.profileData = {
      basic:
      {
        slug: $scope.currentUser.slug,
      },
    };

    $scope.saveMessages = [];
    $scope.saveChanges = function (changedData, formValid, tab) {
      changedData = _.merge({}, changedData);
      ProgressBarService.start();

      if (!_.isUndefined(changedData.avatar) || !_.isUndefined(changedData.gravatar) || !_.isUndefined(changedData.profile_banner)) {
        $scope.fileUploading = true;
        UserService.updateMe(changedData).then((res) => {
          $scope.fileUploading = false;
          $('#changeProfileImg, #changeProfileBg').removeClass('open');
          if (tab === 'avatar') {
            $scope.currentUser.avatar_url += `?cachefix=${Date.now()}`;
          }
          if (tab === 'banner' && res.data.profile_banner) {
            $scope.currentUser.profile_banner = res.data.profile_banner;
          }
          $window.location.reload();
          AlertService.add('Changes successfully saved!', 'success');
        }, (err) => {
          $scope.fileUploading = false;
          AlertService.addDanger(err.data.errors[0].title);
        });
      } else if (!_.isUndefined(changedData.slug) && changedData.slug !== '') {
        UserService.updateMe(changedData).then((res) => {
          $scope.currentUser = res.data;
          $location.url(`profile/${$scope.currentUser.slug}/about`);
          $scope.$on('$locationChangeSuccess', () => {
            $window.location.reload(true);
          });
          AlertService.add('Changes successfully saved!', 'success');
        }, (err) => {
          AlertService.addDanger(err.data.errors[0].title);
        });
      }
      ProgressBarService.done();
    };

    $scope.avatarChange = function (file) {
      $scope.profileData.avatar = { avatar: file };
    };

    $scope.profileBannerChange = function (file) {
      $scope.profileData.banner = { profile_banner: file };
    };

    $scope.mentionStatus = {
      content: `@${$scope.user.slug} `,
    };

    $scope.stats = {
      options: [],
      active: null,
    };

    $scope.graphBuilder = new Array(12);

    $scope.stats.options = _.map($scope.user.site_stats.intervals, (o) => {
      let max = 0;
      const totals = {};

      o.data = _.map(o.data, (o) => {
        o.valuesSum = _.reduce(o.values, (sum, val, key) => {
          if (!totals[key]) {
            totals[key] = val;
          } else {
            totals[key] += val;
          }
          return sum + val;
        }, 0);
        if (o.valuesSum > max) {
          max = o.valuesSum;
        }
        return o;
      });

      o.graphItemMax = max;
      o.totals = totals;
      return o;
    });

    $timeout(() => {
      $scope.stats.active = $scope.stats.options[0];
    }, 200);

    $scope.getPercent = (x, total) => `${(x * 100) / total}%`;

    $timeout(() => {
      const container = $element.find('.ui-select-container');
      if (container.length > 0) {
        _.forEach(container, (elem) => {
          const txt = angular.element(elem).find('.ui-select-match').attr('placeholder');
          elem.setAttribute('aria-label', txt);
          _.forEach(angular.element(elem).find('input'), (hiddenTxt) => {
            if (angular.element(hiddenTxt).attr('autocomplete') === 'off') {
              angular.element(hiddenTxt).remove();
            }
          });
          angular.element(elem).find('.ui-select-match').attr('aria-label', txt);
          angular.element(elem).find('.ui-select-toggle').attr('aria-label', txt);
        });
      }
    }, 200);

    $scope.toggleQuickAdd = () => {
      if (LightTesterErrorService.show()) return;

      $scope.toggleSlideUp('quick-add');
    };
  }
}

ProfileCtrl.$inject = [
  '$scope',
  'UserService',
  'StatusService',
  'CourseService',
  'ToolService',
  'ArticleService',
  'GamificationService',
  'FeedStore',
  'user',
  'CommentService',
  'ProgressBarService',
  'AlertService',
  'ServerMessages',
  'stateStore',
  '$state',
  '$timeout',
  'store',
  'ConfigService',
  'LightTesterErrorService',
  '$q',
  '$location',
  '$window',
  'featureFlags',
  '$element',
];

export default ProfileCtrl;
