import _ from 'lodash';
import angular from 'angular';
import { StateService, Transition } from '@uirouter/core';

class DiscussionCtrl {
  constructor($scope, $state: StateService, $transition$: Transition, $location: ng.ILocationService, AlertService, DiscussionService, DiscussionCategoryService, UserService, randomDiscussions, PaginatorService, LightTesterErrorService, coverBg, headerData, $element: JQuery, $timeout: ng.ITimeoutService) {
    const ctrl: ng.IController = this;
    $scope.randomDiscussions = randomDiscussions.data;
    $scope.coverBg = coverBg;
    $scope.headerData = headerData.data;

    ctrl.$onInit = () => {
      $timeout(() => {
        const container = $element.find('.ui-select-container');
        if (container.length > 0) {
          _.forEach(container, (elem) => {
            const txt = 'Select category';
            elem.setAttribute('aria-label', txt);
            angular.element(elem).find('.ui-select-match').attr('aria-label', txt);
            angular.element(elem).find('.ui-select-toggle').attr('aria-label', txt);
          });
        }
      }, 250);
    };

    DiscussionCategoryService.allForSelect().then((categories) => { $scope.categories = categories; });

    UserService.me().then((res) => {
      if (res !== null) {
        $scope.currentUser = res.data;
        $scope.canCreateTopic = res.data.current_user_permissions.create_topic;
      } else {
        $scope.canCreateTopic = false;
      }
    });

    if ($transition$.params().category_id) {
      $scope.select = { id: parseInt($transition$.params().category_id) };
      $scope.filterCategory = $scope.select;
    } else {
      $scope.filterCategory = {};
    }

    $scope.$watch('filterCategory.id', (newId, oldId) => {
      if (newId === oldId) {
        return;
      }

      $scope.paginationData.currentPage = 1;
      $scope.loadPage($scope.paginationData.currentPage);
    });

    $scope.tab = {};

    if ($transition$.params().tab) {
      $scope.tab[$transition$.params().tab] = true;
    }

    const selectedTab = _.findKey($scope.tab);

    switch (selectedTab) {
      case 'recent-replies':
        $scope.sort = '-last_commented_at';
        break;
      case 'newly-created':
        $scope.sort = '-published_at';
        break;
      case 'most-replies':
        $scope.sort = '-comment_count,-published_at';
        break;
      default:
        $scope.sort = '-last_commented_at';
        break;
    }

    $scope.loadPage = function (pageNumber, initialLoad) {
      $scope.isLoading = true;
      $state.transitionTo('discussion', { tab: selectedTab, topic_page: pageNumber, category_id: $scope.filterCategory.id }, { location: 'replace', notify: false });
      DiscussionService.feed({
        page: pageNumber, sort: $scope.sort, filter: { category_id: $scope.filterCategory.id }, per_page: 20,
      }).then((res) => {
        $scope.paginationData = PaginatorService.makePaginationData(res.headers);
        $scope.discussions = res.data;
        $scope.isLoading = false;
        if (!initialLoad) {
          $location.hash('comment');
        }
      }, (res) => {
        $scope.isLoading = false;
        AlertService.addDanger(res.statusText);
      });
    };

    $scope.loadPage($transition$.params().topic_page, true);

    ctrl.navTabs = document.getElementsByClassName('nav-tabs');
    // Sets aria roles for <ul> <li> and <a>
    setTimeout(() => {
      ctrl.navTabs[0].setAttribute('role', 'tablist');
      const tabElements = ctrl.navTabs[0].children;
      for (let i = 0; i < tabElements.length; i++) {
        tabElements[i].setAttribute('role', 'presentation');
        tabElements[i].firstElementChild.setAttribute('role', 'tab');
        tabElements[i].firstElementChild.setAttribute('aria-selected', tabElements[i].className === 'active');
      }
    });

    $scope.checkLightTester = (e) => {
      if (LightTesterErrorService.show()) {
        e.preventDefault();
      }
    };
  }
}

DiscussionCtrl.$inject = ['$scope', '$state', '$transition$', '$location', 'AlertService', 'DiscussionService', 'DiscussionCategoryService', 'UserService', 'randomDiscussions', 'PaginatorService', 'LightTesterErrorService', 'coverBg', 'headerData', '$element', '$timeout'];

export default DiscussionCtrl;
