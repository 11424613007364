export default `
<div class="academy">
  <div class="container-fluid">
    <div class="academy-header">
      <span ui-sref="academy-home">Academy</span> /
      <span>Quiz - {{$ctrl.courseTrack.name}}</span>
    </div>
    <div class="row">
      <div class="academy-course-track col-md-8">
        <course-track-report-card course-track="$ctrl.courseTrack"></course-track-report-card>
      </div>
      <course-track-nav user="$ctrl.user" course-track="$ctrl.courseTrack"></course-track-nav>
    </div>
  </div>
</div>
`;
