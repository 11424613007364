export default `
<div class="feed-item-footer" ng-if="$ctrl.commentable.type == 'status'">
  <div class="container-fluid">
    <div class="actions">
      <ut-community-feed-item-like-count [likeable]="$ctrl.commentable"></ut-community-feed-item-like-count>
      <ut-community-feed-item-like-btn [likeable]="$ctrl.commentable"></ut-community-feed-item-like-btn>
      <comment-btn commentable="$ctrl.commentable"></comment-btn>
    </div>
    <a
      ng-show="$ctrl.commentable.current_user_permissions.comment"
      ng-click="$ctrl.openCommentEditor()"
      class="clickable"
    >
      <i class="icon icon-chat"></i>Write A Comment
    </a>
  </div>
</div>

<div class="section-single-comment" ng-class="$ctrl.wrapperClass" id="comment">
  <h2 class="sr-only">Comment Section</h2>
  <div class="container-fluid">
    <div ng-if="$ctrl.commentable.type == 'topic' && $ctrl.comments.length" class="section-single-body-filter clearfix">
      <custom-dropdown ng-show="$ctrl.comments.length" on-change="$ctrl.sortingChanged" model="$ctrl.sorting" options="$ctrl.sortingOptions" ></custom-dropdown>
    </div>
  </div>

  <div class="block container-fluid" >
    <div ng-show="!$ctrl.isLoading" >

      <div class="feed-loading-message" ng-show="!$ctrl.comments.length && $ctrl.search.query.length">
        No results
      </div>

      <!-- CommentsTitle -->
      <div ng-if="$ctrl.commentable.type !== 'topic'" class="clearfix mb3">
        <div class="section-single-comment-heading pull-left">
          {{$ctrl.paginationData.totalItems|pluralize:'comment'}}
        </div>
        <div class="pull-right">
          <custom-dropdown on-change="$ctrl.sortingChanged" model="$ctrl.sorting" options="$ctrl.sortingOptions" ></custom-dropdown>
        </div>
      </div>
      <!-- CommentsTitle -->

      <!-- CommentItems ng-repeat="comment in comments" -->
      <div class="section-single-comment-content feed" ng-repeat="comment in $ctrl.comments" id="comment_{{comment.id}}" ng-class="comment.id == $ctrl.selectedComment ? 'selected-comment' : ''" on-finish-render="ngRepeatFinished">
        <div class="indent" ng-class="'indent-' + comment.indentation" ng-if="($ctrl.commentable.type != 'topic' || ($ctrl.commentable.type == 'topic' && $ctrl.commentable.content != comment.content) || ($ctrl.commentable.type == 'topic' && $ctrl.commentable.content == comment.content && $ctrl.commentable.user.id != comment.user.id))">
          <div class="feed-header clearfix">
            <a class="feed-header-info" ui-sref="profile.about({profileId: comment.user.slug})" aria-label="Comment Author, {{comment.user.name}}">
              <div class="feed-header-info-icon">
                <div avatar-badges="comment.user" class="profile-thumb"></div>
              </div>
              <div class="feed-header-info-name">
                <span class="feed-header-info-name__text">{{comment.user.name}}
                  <span ng-if="comment.user.country.name">({{comment.user.country.name}})</span></span>
                <div class="feed-item-header-title comment-header-title" ng-if="comment.user.user_title">{{comment.user.user_title | strLimit: 50}}</div>
                <span class="time-stamp">{{comment.published_at | relativeDate}}</span>
              </div>
            </a>
            <div class="func-btns" ng-class="$ctrl.commentDeletionClass">
              <div class="main-btns">
                <button id="deleteCommentBtn" ng-show="comment.current_user_permissions.destroy" class="btn btn-rounded small btn-outline delete-button" ng-click="$ctrl.commentDeletionClass = 'deleted';$ctrl.focusBtn('delete')" aria-hidden="false">Delete</button>
                <button id="editCommentBtn" ng-show="comment.current_user_permissions.update" ng-click="$ctrl.openCommentEditor(comment)" class="btn small btn-outline btn-rounded edit-button" aria-hidden="{{$ctrl.commentDeletionClass === 'deleted'}}">Edit</button>
              </div>
              <div ng-show="comment.current_user_permissions.destroy" class="delete-confirmation">
                Are you sure?
                <button id="deleteCommentBtnNo" class="btn btn-rounded small delete-button" ng-click="$ctrl.commentDeletionClass = '';$ctrl.focusBtn('no')" ng-keydown="$ctrl.handleKeydownDeleteConformation($event)" aria-hidden="true" aria-label="Are you sure you want to delete?, No" tabindex="{{$ctrl.commentDeletionClass === 'deleted' ? 0 : -1}}"><div>No</div></button>
                <button id="deleteCommentBtnYes" class="btn btn-rounded small delete-button" ng-click="$ctrl.deleteComment(comment.id, $index)" ng-keydown="$ctrl.handleKeydownDeleteConformation($event)" aria-hidden="{{$ctrl.commentDeletionClass !== 'deleted'}}" aria-label="Are you sure you want to delete?, Yes, Delete" tabindex="{{$ctrl.commentDeletionClass === 'deleted' ? 0 : -1}}"><div>Yes, Delete</div></button>
              </div>
            </div>
          </div>
          <div class="feed-content">
            <div class="rating"><uib-rating ng-model="comment.rating" max="5" state-on="'icon-star-rated'" state-off="'icon-star'" readonly="true" ng-show="comment.rating >= 0"></uib-rating></div>
            <article ng-bind-html="comment.content_built"></article>
            <a class="feed-content__reply-link" ng-if="$ctrl.commentable.type !== 'topic' && $ctrl.commentable.current_user_permissions.comment" href="#" ng-click="$ctrl.openCommentEditor({parent_id:comment.id, reply_index: $index, content: '@' + comment.user.slug + ' '})" ><i class="icon icon-reply"></i>Reply</a>
            <div class="func-btns">
              <community-feed-item-hide item="comment" ng-show="comment.current_user_permissions.hide"></community-feed-item-hide>
              <span ng-show="$ctrl.commentable.current_user_permissions.comment && $ctrl.commentable.type == 'topic'" class="clickable" ng-click="$ctrl.openCommentEditor(comment, 'quote')" >
                <i class="material-icons format-quote-icon">format_quote</i>
              </span>
              <ut-community-feed-item-like-count [likeable]="comment"></ut-community-feed-item-like-count>
              <ut-community-feed-item-like-btn [likeable]="comment"></ut-community-feed-item-like-btn>
              <community-feed-item-flag item="comment" ng-show="comment.current_user_permissions.flag"></community-feed-item-flag>
            </div>
          </div>
        </div>
      </div>
      <!-- CommentItems -->

      <!-- CommentPagination -->
      <feed-paginator pagination-data="$ctrl.paginationData" load-page="$ctrl.loadPage"></feed-paginator>
      <!-- /CommentPagination -->

    </div>
    <loading-dots ng-if="$ctrl.isLoading" ></loading-dots>
  </div>

  <!-- Write a comment Button -->
  <div class="block container-fluid" ng-show="$ctrl.commentable.current_user_permissions.comment && !$ctrl.isLoading && !$ctrl.commentable.locked" >
    <div class="comment-btn">
      <a ng-bind="$ctrl.commentBtnLabel" href="#" class="btn btn-blue" ng-click="$ctrl.openCommentEditor() && $ctrl.broadcastOpening()"></a>
    </div>
  </div>
  <div class="block container-fluid" ng-if="!$ctrl.currentUser && !$ctrl.isLoading && !$ctrl.commentable.locked" >
    <div class="comment-btn">
      <a ng-bind="$ctrl.commentBtnLabel" ng-href="{{$ctrl.loginUrl}}" target="_self" class="btn btn-filled btn-rounded small"></a>
    </div>
  </div>
  <!-- /Write a comment Button -->

  <!-- Write a comment Form -->
  <section ng-class="$ctrl.commentable.type" class="slide-up slide-up-move comment-form" id="singleCommentSection">
    <form ng-submit="$ctrl.submitComment()" >
      <div class="comment-form-rating">
        <h4>Your Rating:</h4>
        <div class="rating clickable"><uib-rating ng-model="$ctrl.newComment.rating" max="5" state-on="'icon-star-rated'" state-off="'icon-star'" readonly="false"></uib-rating></div>
      </div>
      <div class="comment-form-reply">
        <label for="slideUpTextArea">Write Your reply:</label>
        <ut-editor [(value)]="$ctrl.newComment.content" text-area-label="Write your reply here"></ut-editor>
      </div>
      <div class="comment-form-btn">
        <button type="submit" class="btn slide-up-submit">Submit</button>
      </div>
    </form>
  </section>
  <!-- /Write a comment Form -->

</div>
`;
