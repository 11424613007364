export default `
<div id="mainContent">
  <community-nav ng-if="$ctrl.currentUser"></community-nav>
  <div class="section-home-cover">
    <div class="section-home-cover-bg">
      <div bg-img="{{$ctrl.coverBg}}" swap="{{$ctrl.headerData.coverBg.large}}" ></div>
    </div>
    <div class="container-fluid block">
      <a id="coverBtn" ui-sref="newarticle" class="btn new-article" ng-if="$ctrl.currentUser" ng-click="$ctrl.checkLightTester($event)">
        <span class="icon icon-write" aria-hidden="true"></span>Write an Article
      </a>
      <h1 class="section-title">{{$ctrl.headerData.title}}</h1>
      <p class="section-desc" ng-bind-html="$ctrl.headerData.description"></p>
    </div>
  </div>
  <div class="container-fluid block">
    <div style="padding-bottom:1rem;">
      <h2>Official uTest Articles</h2>
    </div>
    <div class="container-fluid">
      <article-feed-item article="article" ng-repeat="article in $ctrl.officialArticles.feedItems" class="section-home-feed"></article-feed-item>
    </div>
    <load-more load-callback="$ctrl.officialArticles.loadMore()" is-loading="$ctrl.officialArticles.isLoading" is-last-page="$ctrl.officialArticles.isLastPage" on-finish-render="ngRepeatFinished" fade-target="footer"></load-more>
  </div>
  <div class="container-fluid">
    <div style="padding-bottom:1rem;">
      <h3>Community uTest Articles</h3>
    </div>
    <div class="container-fluid">
      <article-feed-item article="article" ng-repeat="article in $ctrl.contributedArticles.feedItems" class="section-home-feed"></article-feed-item>
    </div>
    <load-more load-callback="$ctrl.contributedArticles.loadMore()" is-loading="$ctrl.contributedArticles.isLoading" is-last-page="$ctrl.contributedArticles.isLastPage" on-finish-render="ngRepeatFinished" fade-target="footer"></load-more>
  </div>
  <div class="container-fluid">
    <div style="padding-bottom:1rem;">
      <h4>Most Popular uTest Articles</h4>
    </div>
    <div class="container-fluid">
      <article-feed-item article="article" ng-repeat="article in $ctrl.popularArticles.feedItems" class="section-home-feed"></article-feed-item>
    </div>
    <load-more load-callback="$ctrl.popularArticles.loadMore()" is-loading="$ctrl.popularArticles.isLoading" is-last-page="$ctrl.popularArticles.isLastPage" on-finish-render="ngRepeatFinished" fade-target="footer"></load-more>
  </div>
</div>
`;
