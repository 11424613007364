export default `
<div class="panel course-track-card">
  <div class="panel-bar"></div>
  <div class="panel-heading">
    <div class="panel-done clearfix">
      <i class="material-icons pull-right" ng-if="$ctrl.courseTrackCompleted" aria-label="Course Completed, {{$ctrl.courseTrack.name}}" role="img">check_circle_outline</i>
    </div>
    <h2 class="panel-title course-track-name pull-left" ng-bind="$ctrl.courseTrack.name"></h2>
  </div>
  <div class="panel-body">
    <span class="course-track-description" ng-bind="$ctrl.courseTrack.description"></span>
    <div class="course-track-list-details">
      <button ng-mouseenter="$ctrl.togglePopover($event)" ng-mouseleave="$ctrl.viewLessonsMouseout($event)" ng-keydown="$ctrl.togglePopover($event)" ng-click="$ctrl.viewLessonsClick($event)" ng-focus="$ctrl.togglePopover($event)" ng-blur="$ctrl.closePopover()" class="clickable hidden-xs" aria-label="View Lessons for {{$ctrl.courseTrack.name}}" ng-if="$ctrl.courseTrack.courses.length" aria-describedby="tooltip-{{$ctrl.courseTrack.id}}" aria-expanded="{{$ctrl.isPopoverOpen}}">
        View Lessons
      </button>
      <button class="hidden-sm hidden-md hidden-lg" ng-click="$ctrl.toggleAccordion()" ng-bind="$ctrl.showAccordion ? 'Hide Lessons' : 'View Lessons'" aria-label="{{$ctrl.showAccordion ? 'Hide Lessons for ' : 'View Lessons for '}}{{$ctrl.courseTrack.name}}" ng-if="$ctrl.courseTrack.courses.length" aria-expanded="{{$ctrl.isPopoverOpen}}"></button>
      <div class="course-track-list-popover hidden-xs">
        <div class="course-track-list-popover__body" ng-click="$ctrl.closePopover()" id="tooltip-{{$ctrl.courseTrack.id}}" role="tooltip">
          <h3>Lessons Covered</h3>
          <loading-dots ng-if="!$ctrl.courses"></loading-dots>
          <ul>
            <li ng-repeat="course in $ctrl.courseTrack.courses | limitTo: 6 : 0" ng-bind="course.title"></li>
            <li class="additional-courses" ng-if="$ctrl.courseTrack.courses.length > 6">{{$ctrl.courseTrack.courses.length - 6}} additional {{$ctrl.courseTrack.courses.length - 6 == 1 ? 'lesson' : 'lessons'}}</li>
          </ul>
          <div class="course-track-list-popover__arrow"></div>
        </div>
      </div>
      <div class="course-track-list-accordion hidden-sm hidden-md hidden-lg" ng-show="$ctrl.showAccordion">
        <div class="course-track-list-accordion__body" ng-click="$ctrl.closePopover()">
          <h5>Lessons Covered</h5>
          <loading-dots ng-if="!$ctrl.courses"/></loading-dots>
          <ul>
            <li ng-repeat="course in $ctrl.courseTrack.courses | limitTo: 6 : 0" ng-bind="course.title"></li>
            <li class="additional-courses" ng-show="$ctrl.courseTrack.courses.length > 6">{{$ctrl.courseTrack.courses.length - 6}} additional {{$ctrl.courseTrack.courses.length - 6 == 1 ? 'lesson' : 'lessons'}}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="panel-footer">
    <a class="course-track-view"
    ng-disabled="$ctrl.isLightTester && $ctrl.trackPosition > 2"
    ng-class="{'completed': $ctrl.courseTrackCompleted}"
    ng-mouseenter="$ctrl.showTooltip()"
    ng-mouseleave="$ctrl.hideTooltip()"
    ui-sref="tracks({ trackId: $ctrl.courseTrack.id })" aria-label="{{$ctrl.courseTrackCompleted ? 'Revisit Course' : 'Take Course'}} - {{$ctrl.courseTrack.name}}" ng-if="$ctrl.courseTrack.courses.length">
      {{$ctrl.courseTrackCompleted ? 'Revisit Course' : 'Take Course'}}
    </a>
  </div>
</div>
`;
