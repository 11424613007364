/*
 * Copyright (C) 2020 to Present Applause App Quality, Inc. All rights reserved.
 */

import {
  DoBootstrap,
  NgModule,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UpgradeModule } from '@angular/upgrade/static';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { EasymdeModule } from 'ngx-easymde';
import { AutocompleteModule } from '@ng-falcon/controls/autocomplete/autocomplete.module';
import { AppComponent } from './app.component';
import { HomeModule } from './home/home.module';
import './downgraded-components';
import { UtEditorModule } from './common/ut-editor/ut-editor.module';
import { CommunityModule } from './common/community/community.module';
import { CommonComponentsModule } from './common/components/common-components.module';

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    UpgradeModule,
    FormsModule,
    HomeModule,
    UtEditorModule,
    UIRouterUpgradeModule.forRoot(),
    EasymdeModule.forRoot(),
    AutocompleteModule,
    CommunityModule,
    CommonComponentsModule,
  ],
  declarations: [
    AppComponent,
  ],
  providers: [
    Meta,
  ],
})
class AppModule implements DoBootstrap {
  constructor(private upgrade: UpgradeModule) { }

  ngDoBootstrap() {
    this.upgrade.bootstrap(document.body, ['uTestV2']);
  }
}

export { AppModule };
