export default `
<div class="community-trending">
  <h3 class="community-trending__header">What's Trending</h3>
  <div class="community-trending-list">
    <div ng-if="!$ctrl.feed.length" class="community-trending-list-item-skeleton"></div>
    <div ng-if="!$ctrl.feed.length" class="community-trending-list-item-skeleton"></div>
    <div ng-if="!$ctrl.feed.length" class="community-trending-list-item-skeleton"></div>
    <div ng-if="!$ctrl.feed.length" class="community-trending-list-item-skeleton"></div>
    <community-trending-list-item item="item" ng-repeat="item in $ctrl.feed | limitTo: $ctrl.showMore ? 10 : 4" on-finish-render="ngRepeatFinished"/>
    <a ng-click="$ctrl.toggleShowMore()" class="community-trending__show-more">{{$ctrl.showMore ? 'Show Less' : 'Show More'}}</a>
  </div>
</div>
`;
