export default `
<div>
  <div class="feed-item-footer" ng-if="$ctrl.commentable.type == 'status'">
    <div class="actions">
      <ut-community-feed-item-like-count [likeable]="$ctrl.commentable"></ut-community-feed-item-like-count>
      <ut-community-feed-item-like-btn [likeable]="$ctrl.commentable"></ut-community-feed-item-like-btn>
      <comment-btn commentable="$ctrl.commentable"></comment-btn>
    </div>
    <a
      ng-show="$ctrl.commentable.current_user_permissions.comment"
      ng-click="$ctrl.openCommentEditor()"
      class="clickable"
    >
      <i class="icon icon-chat"></i>Write A Comment
    </a>
  </div>

  <div class="section-single-comment section-single-comment-dark" >

    <!-- CommentItems ng-repeat="comment in comments" -->
    <div class="section-single-comment-content feed"  ng-repeat="comment in $ctrl.comments" id="comment_{{comment.id}}" ng-class="comment.id == $ctrl.selectedComment ? 'selected-comment' : ''">
      <div class="indent" ng-class="'indent-' + comment.indentation" >
        <a class="feed-header clearfix" ui-sref="profile.about({profileId: comment.user.slug})" aria-label="Comment Author, {{comment.user.name}}">
          <div class="feed-header-info-icon">
            <div class="profile-thumb" bg-img="{{comment.user.avatar_url}}"></div>
          </div>
          <div class="feed-header-info-name">
              {{comment.user.name}}
                <span ng-if="comment.user.country.name">({{comment.user.country.name}})</span>
            <div class="feed-item-header-title comment-header-title" ng-if="comment.user.user_title">
              {{comment.user.user_title | strLimit: 50}}
            </div>
            <span class="time-stamp">{{comment.published_at | relativeDate}}</span>
          </div>
        </a>
        <div class="feed-content">
          <article ng-bind-html="comment.content_built"></article>
          <a ng-click="$ctrl.openCommentEditor({parent_id:comment.id, content: '@' + comment.user.slug + ' '})" class="clickable" ><i class="icon icon-reply"></i>Reply</a>
          <div class="func-btns">
            <community-feed-item-flag item="comment" ng-show="comment.current_user_permissions.flag"></community-feed-item-flag>
            <ut-community-feed-item-like-count [likeable]="comment"></ut-community-feed-item-like-count>
            <ut-community-feed-item-like-btn [likeable]="comment"></ut-community-feed-item-like-btn>
          </div>
        </div>
      </div>
    </div>
    <!-- CommentItems -->

    <!-- Footer -->
    <div ng-if="$ctrl.comments.length && ($ctrl.comments.length < $ctrl.commentable.comments.count)" class="section-single-comment-short-footer">
      <a class="" ui-sref="singlestatus({statusId: $ctrl.commentable.id})" >View All Comments</a>
    </div>
    <!-- Footer -->

  </div>

  <!-- Write a comment Form -->
  <section ng-class="$ctrl.commentable.type" class="slide-up slide-up-move comment-form" ng-attr-id="{{$ctrl.slideUpId}}">
    <form ng-submit="$ctrl.submitComment()" >
      <div class="comment-form-rating">
        <h4>Your Rating:</h4>
        <div class="rating clickable"><uib-rating ng-model="$ctrl.newComment.rating" max="5" state-on="'icon-star-rated'" state-off="'icon-star'" readonly="false"></uib-rating></div>
      </div>
      <div class="comment-form-reply">
        <label for="slideUpTextArea">Write Your reply:</label>
        <ut-editor [(value)]="$ctrl.newComment.content" text-area-label="Write your reply here"></ut-editor>
      </div>
      <div class="comment-form-btn">
        <button type="submit" class="btn btn-yellow">Submit</button>
      </div>
    </form>
  </section>
  <!-- /Write a comment Form -->

</div>
`;
