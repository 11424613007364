export default `
<div class="set-password">
<div class="simple-registration-page-header sign-in-box">
    <div class="text-left col-md-4 col-lg-4 col-sm-6 col-xs-6 logo-container">
        <img class="text-left utest-simple-registration-logo" src="/assets/images/uTestLogoNavDark.svg" alt="uTest Logo">
    </div>
</div>
<div class="container-fluid">
  <div class="clearfix">
    <div ng-show="$ctrl.linkValid && !$ctrl.linkExpired" class="sign-up-form-container">
     <form novalidate name="setPasswordForm" class="user-form" ng-class="{'invalid': (!setPasswordForm.$valid && setPasswordForm.$$submitted)}">

        <div class="set-password-form-group-box">
          <div>
             <h3 class="form-header-text">Set Password</h3>
         </div>
         <div class="set-password-requirements-list">
             Your password must:
             <ul style="margin-top: 0">
                 <li class="set-password-requirement-list-item">Be at least 10 characters long</li>
                 <li class="set-password-requirement-list-item">Contain at least 1 number</li>
                 <li class="set-password-requirement-list-item">Contain at least 1 lowercase letter</li>
                 <li class="set-password-requirement-list-item">Contain at least 1 uppercase letter</li>
                 <li class="set-password-requirement-list-item">Not match any of your last 10 passwords</li>
             </ul>
         </div>
          <div class="row">
            <div class="form-group col-sm-12">
              <div>
                <label for="password">New Password</label>
              </div>
              <input required type="password" name="password" id="password" autocomplete="new-password" ng-minlength="10" ng-maxlength="64"
                  ng-model="$ctrl.passwordData.password" aria-label="New Password"
                  aria-describedby="{{(setPasswordForm.$$submitted && !!setPasswordForm.password.$invalid) ? 'missingPassword' : ''}}"
                  aria-invalid="{{setPasswordForm.$$submitted && !!setPasswordForm.password.$invalid}}">
              <span id="missingPassword" class="error-msg" ng-show="!setPasswordForm.password.$valid && setPasswordForm.$$submitted" aria-live="polite">
                {{ setPasswordForm.password.$error.required ? 'Password is required' : '' }}
              </span>
             <div class="sr-only" id="passwordInstruction" aria-hidden="true">
              Password Instructions, Use 10 to 64 characters. Must include a lower-case letter, an upper-case letter, and a number. Must not include email address. For added security, we do not allow common passwords. Password is case sensitive. Avoid using the same password for multiple sites.
             </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-sm-12">
               <div>
                 <label for="confirmPassword">Confirm password</label>
               </div>
               <input required type="password" name="confirmPassword" id="confirmPassword" validation-match="$ctrl.passwordData.password" autocomplete="new-password" ng-minlength="10" ng-maxlength="64"
                      ng-model="$ctrl.passwordData.confirmPassword" aria-label="Confirm password"
                      aria-describedby="{{setPasswordForm.$$submitted && !!setPasswordForm.confirmPassword.$invalid ? 'passConfirmError' : ''}}"
                      aria-invalid="{{setPasswordForm.$$submitted && !!setPasswordForm.confirmPassword.$invalid}}">
                 <span id="passConfirmError" class="error-msg" ng-show="!setPasswordForm.confirmPassword.$valid && setPasswordForm.$$submitted" aria-live="polite">
                    {{setPasswordForm.confirmPassword.$error.required ? 'Confirm Password is required' : 'Password mismatch'}}
                 </span>
            </div>
          </div>

          <div class="pull-left submit-button simple-registration-btn-container set-password-container">
            <button class="btn btn-blue simple-registration-btn" ng-click="$ctrl.submitForm(setPasswordForm);" id="laddaBtn"
              aria-label="Save Password"
              aria-invalid="{{setPasswordForm.$$submitted && !!setPasswordForm.$invalid}}">
              <span class="set-password-btn-text">Save Password</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<simple-registration-set-password-link-expired ng-show="$ctrl.linkValid && $ctrl.linkExpired"></simple-registration-set-password-link-expired>
<simple-registration-set-password-error-page ng-show="!$ctrl.linkValid"></simple-registration-set-password-error-page>
</div>
`;

