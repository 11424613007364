export default `
<div class="course-track-nav-container col-md-4" ng-if="$ctrl.user">
  <div class="course-track-nav">
    <div class="course-track-name" ng-bind="$ctrl.courseTrack.name"></div>

    <div class="course-track-courses" role="list">
      <course-track-nav-item selected-course="$ctrl.course" nav-course="navCourse" course-track="$ctrl.courseTrack" failed-courses="$ctrl.failedCourses" ng-repeat="navCourse in $ctrl.courses" role="listitem"></course-track-nav-item>
    </div>

    <div class="course-track-nav-quiz">
      <div class="course-track-nav-quiz-button-container" ng-if="$ctrl.courseTrack.has_quiz && !$ctrl.courseTrack.user_has_passed_quiz && !$ctrl.userIsTakingQuiz">
        <button class="take-quiz" ng-class="{'active': $ctrl.courseTrack.user_can_take_quiz}" aria-disabled="{{!$ctrl.courseTrack.user_can_take_quiz}}" ng-disabled="!$ctrl.courseTrack.user_can_take_quiz" ui-sref="quiz({trackId: $ctrl.courseTrack.id})">
          Take the Quiz
        </button>
        <div class="instructions" ng-hide="$ctrl.courseTrack.user_can_take_quiz || $ctrl.failedCourses.length > 0">
          Read all the lessons in this course to unlock the quiz.
        </div>
      </div>

      <div ng-show="$ctrl.courseTrack.has_quiz && ($ctrl.courseTrack.user_has_passed_quiz || $ctrl.userIsTakingQuiz)" class="course-track-nav-quiz__nav-item" ng-class="{'taking-quiz' : $ctrl.userIsTakingQuiz}">
        <div class="quiz-title">Quiz - {{$ctrl.courseTrack.name}}</div>
        <div class="quiz-completed" ng-show="$ctrl.courseTrack.user_has_passed_quiz">
          <i class="material-icons" aria-label="Quiz Completed">star</i>
        </div>
      </div>

      <div class="test-cycle-fail-message" ng-show="$ctrl.failedCourses.length > 0 && !$ctrl.courseTrack.user_can_take_quiz">
        Review the lessons above marked with an
          <i class="material-icons" style="vertical-align: text-top;" aria-label="X">highlight_off</i>
         before you retake the quiz.
      </div>
    </div>

    <div class="course-track-nav-quiz__nav-item" ng-if="$ctrl.courseTrack.user_has_passed_practice_test_cycle_except_lv_5">
      <div class="quiz-title">Practice Test Cycle - {{$ctrl.courseTrack.name}}</div>
      <div class="quiz-completed">
        <i class="material-icons" aria-label="Quiz Completed">star</i>
      </div>
    </div>

    <div class="practice-test-cycle" ng-if="$ctrl.courseTrack.has_practice_test_cycle && !$ctrl.courseTrack.user_has_passed_practice_test_cycle_except_lv_5" ng-class="{'incomplete-quiz' : !$ctrl.courseTrack.user_can_apply_to_test_cycle }">
      <div class="locked" ng-if="!$ctrl.courseTrack.user_can_apply_to_test_cycle">
        <i class="material-icons" aria-label="Locked">
          lock
        </i>
      </div>
      <div class="unlocked" ng-if="$ctrl.courseTrack.user_can_apply_to_test_cycle">
        <i class="material-icons" aria-label="Unlocked">
          lock_open
        </i>
      </div>
      <div class="test-cycle">
        <div class="test-cycle-title">Practice Test Cycle - {{$ctrl.courseTrack.name}}</div>
        <div class="test-cycle-details" ng-if="!$ctrl.courseTrack.user_can_apply_to_test_cycle" ng-show="!$ctrl.courseTrack.has_quiz && !$ctrl.courseTrack.has_prerequisite_test_cycle">Complete this course to unlock the practice test cycle.</div>
        <div class="test-cycle-details" ng-if="!$ctrl.courseTrack.user_can_apply_to_test_cycle" ng-show="$ctrl.courseTrack.has_quiz && !$ctrl.courseTrack.has_prerequisite_test_cycle">Complete this course and pass the quiz to unlock the practice test cycle.</div>
        <div class="test-cycle-details" ng-if="!$ctrl.courseTrack.user_can_apply_to_test_cycle" ng-show="!$ctrl.courseTrack.has_quiz && $ctrl.courseTrack.has_prerequisite_test_cycle">Complete this course and pass the preceding practice test cycle to unlock the practice test cycle.</div>
        <div class="test-cycle-details" ng-if="!$ctrl.courseTrack.user_can_apply_to_test_cycle" ng-show="$ctrl.courseTrack.has_quiz && $ctrl.courseTrack.has_prerequisite_test_cycle">Complete this course, pass the quiz, and pass the preceding practice test cycle to unlock the practice test cycle.</div>
        <div class="test-cycle-details" ng-if="$ctrl.courseTrack.user_can_apply_to_test_cycle">
          You’ve unlocked the practice test cycle! Sign up to get practice in a live testing environment.
        </div>
      </div>
      <button ng-if="$ctrl.courseTrack.user_can_apply_to_test_cycle" class="academy-return" ng-click="$ctrl.testCycleSignUp()">
        Sign Up
      </button>
    </div>
    </div>
</div>
`;
