class CommunityTrendingCtrl {
  constructor() {
    const ctrl: ng.IController = this;
    ctrl.$onInit = () => {
      ctrl.showMore = false;
    };

    ctrl.toggleShowMore = () => {
      ctrl.showMore = !ctrl.showMore;
    };
  }
}
export default CommunityTrendingCtrl;
