export default `
<div class="feed tool">
  <div class="feed-header clearfix" ng-show="$ctrl.includeHeaderInfo">
    <div class="feed-header-info">
      <div class="feed-header-info-icon">
        <a ui-sref="profile.about({profileId: $ctrl.tool.user.slug})"><div class="profile-thumb" bg-img="{{$ctrl.tool.user.avatar_url}}"></div></a>
      </div>
      <div class="feed-header-info-name">
        <h5><a class="dark" ui-sref="profile.about({profileId: $ctrl.tool.user.slug})">{{$ctrl.tool.user.name | strLimit: 30}}</a></h5>
        <span class="feed-type">Tool • </span>
        <span class="time-stamp">{{$ctrl.tool.published_at | relativeDate}}</span>
      </div>
    </div>
    <div class="func-btns">
      <social-sharing is-popover="true" target="$ctrl.tool.url" text="$ctrl.tool.name"></social-sharing>
    </div>
  </div>
  <div class="feed-img hidden-xs" bg-img="{{$ctrl.tool.icon.url}}"></div>
  <div class="feed-header">
    <h2><a ui-sref="singletool({toolId : $ctrl.tool.slug})" tooltip="{{$ctrl.tool.name}}">{{$ctrl.tool.name}}</a></h2>
    <div class="rating" ng-if="$ctrl.tool.comments.avg_rating"><span>{{$ctrl.tool.comments.avg_rating}}</span><i class="glyphicon icon-star-rated"></i> ({{$ctrl.tool.comments.count}})</div>
  </div>
  <div class="feed-content">
    <div class="feed-content-main" ng-if="!$ctrl.featured">
      <p>{{$ctrl.tool.description | cleanUp}}</p>
    </div>
    <div class="feed-content-footer">
      <div class="tags">
        <ut-tag-btn [tag]="tag" ng-repeat="tag in $ctrl.tool.tags | limitTo: 2"></ut-tag-btn>
      </div>
      <div class="status-btns">
        <comment-btn commentable="$ctrl.tool"></comment-btn>
      </div>
    </div>
  </div>
</div>
`;
