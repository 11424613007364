import zxcvbn from 'zxcvbn';
import _ from 'lodash';

const communityRegistrationPasswordStrengthMeter = function ($compile) {
  return {
    scope: {
      registrationData: '=',
    },
    restrict: 'A',
    require: 'ngModel',
    link(scope, element, attrs, ngModelCtrl) {
      function getStrengthText(score) {
        switch (score) {
          case 2:
            return 'So so';
          case 3:
            return 'Good';
          case 4:
            return 'Great';
          case null:
            return '';
          default:
            return 'Weak';
        }
      }

      function checkPasswordStrength(oldPassword = '') {
        const { email } = scope.registrationData;
        const password = element.val() || oldPassword;
        const itwPasswordCriteria: RegExp = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{10,64}$/;
        const invalidSubstrings = email && email.length > 0 && _.includes(password, email);
        const meetsItwCriteria = itwPasswordCriteria.test(password) && !invalidSubstrings;
        const complexity = zxcvbn(password);

        // the zxcvbn score is only relevant if it meets the auth service's criteria
        if (password.length === 0) {
          scope.passwordStrength = null;
        } else if (meetsItwCriteria && complexity) {
          scope.passwordStrength = complexity.score;
        } else {
          scope.passwordStrength = 0;
        }
        scope.passStrengthText = getStrengthText(scope.passwordStrength);
        ngModelCtrl.$setValidity('okPassword', scope.passwordStrength >= 2);
      }

      scope.passwordPopoverOpen = false;

      const template = $compile(`
        <div class="strength-meter"
             uib-popover-template="\'app/community-registration/community-registration-password-strength-info.html\'"
             popover-trigger="none"
             popover-is-open="passwordPopoverOpen"
             popover-placement="bottom"
        >
          <div class="strength-meter-fill" data-strength="{{passwordStrength}}"></div>
        </div>
      `)(scope);

      element.after(template);
      element.on('focus', (_evt) => {
        scope.passwordPopoverOpen = true;
        scope.$apply();
      });

      element.on('blur', (_evt) => {
        scope.passwordPopoverOpen = false;
        scope.$apply();
      });

      ngModelCtrl.$viewChangeListeners.push(
        () => {
          scope.$evalAsync(() => {
            checkPasswordStrength();
          });
        },
      );

      if (scope.registrationData.password) {
        scope.$evalAsync(() => {
          checkPasswordStrength(scope.registrationData.password);
        });
      }
    },
  };
};

communityRegistrationPasswordStrengthMeter.$inject = ['$compile'];

export default communityRegistrationPasswordStrengthMeter;
